/* eslint-disable react/no-children-prop */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import BookingsPageLink from './Links/BookingsPageLink';
import EmailLink from './Links/EmailLink';
import PhoneLink from './Links/PhoneLink';

const PersonToContact = ({ authorId, title }) => {
  const { allAuthorsJson } = useStaticQuery(graphql`
    query PersonToContactQuery {
      allAuthorsJson {
        edges {
          node {
            id
            name
            image
            role
            phone
            email
            url {
              link
              label
            }
          }
        }
      }
    }
  `);

  const authorEdge = allAuthorsJson.edges.find(
    ({ node }) => node.id === authorId
  );

  const author = authorEdge ? authorEdge.node : null;

  if (!author) {
    return <></>;
  }

  const { image, name, role, phone, email, url } = author;

  return (
    author && (
      <div className="container person-to-contact">
        <div className="row">
          <h3>{title}</h3>
        </div>
        <br />
        <div className="row pb-2 pt-2 person-to-contact-wrapper">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="person-to-contact-image">
              <img src={image} alt={`${name}`} />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 person-to-contact-details-wrapper">
            <div className="person-to-contact-announcement">
              <b>{name && <ReactMarkdown children={name} />}</b>
            </div>
            <div>{role && <ReactMarkdown children={role} />}</div>
            <PhoneLink phone={phone} />
            <EmailLink email={email} />
            {url && <BookingsPageLink link={url.link} label={url.label} />}
          </div>
        </div>
      </div>
    )
  );
};

export default PersonToContact;
