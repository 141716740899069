import { Link } from 'gatsby';
import React from 'react';
import Icon from '../Icon';

const BookingsPageLink = ({ link, label }) =>
  link && (
    <div>
      <Icon
        iconName="Calendar"
        style={{ fontWeight: 'bold', padding: '2px' }}
      />
      {'  '}
      <Link target="_blank" rel="noopener noreferrer" to={link}>
        {label || 'Link'}
      </Link>
    </div>
  );

export default BookingsPageLink;
