/* eslint-disable react/no-children-prop */
import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

const Author = ({ authorId }) => {
  const { allAuthorsJson } = useStaticQuery(graphql`
    query AuthorsQuery {
      allAuthorsJson {
        edges {
          node {
            id
            name
            image
            bio
          }
        }
      }
    }
  `);

  const authorEdge = allAuthorsJson.edges.find(
    ({ node }) => node.id === authorId
  );

  const author = authorEdge ? authorEdge.node : null;

  return (
    author && (
      <div className="container author">
        <div className="row author-announcement">Blog post written by</div>
        <br />
        <div className="row pb-2 pt-2 author-wrapper">
          <div className="col-12 col-md-3 col-lg-3">
            <div className="author-image">
              <img src={author.image} alt={`${author.name}`} />
            </div>
          </div>
          <div className="col-12 col-md-9 col-lg-9">
            {author.bio && (
              <div className="author-bio">
                <ReactMarkdown children={author.bio} />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Author;
