import React from 'react';
import Icon from '../Icon';

const PhoneLink = ({ phone }) =>
  phone && (
    <div>
      <Icon iconName="Phone" style={{ fontWeight: 'bold', padding: '2px' }} />
      {'  '}
      <a href={`tel:${phone.replace(/\s/g, '')}`}>{phone}</a>
    </div>
  );

export default PhoneLink;
