import React from 'react';
import Icon from '../Icon';

const EmailLink = ({ email }) =>
  email && (
    <div>
      <Icon iconName="Mail" style={{ fontWeight: 'bold', padding: '2px' }} />
      {'  '}
      <a href={`mailto:${email}`}>{email}</a>
    </div>
  );

export default EmailLink;
